import {
  GET_CURATED_PROFILES,
  CREATE_CURATED_PROFILE,
  UPDATE_CURATED_PROFILE,
  CREATE_COIN,
  UPDATE_COIN,
  DELETE_CURATED_PROFILE,
  DELETE_COIN,
} from "./types";
import axios from "axios";

export const getCuratedProfiles = () => async (dispatch) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/curated-profiles?timestamp=${new Date().getTime()}`,
    );

    return dispatch({
      type: GET_CURATED_PROFILES,
      payload: data.data.profiles,
    });
  } catch (error) {
    return error;
  }
};

export const createCuratedProfile = (payload) => async (dispatch) => {
  try {
    const newPayload = {
      ...payload,
      archiveDate:
        payload.isArchived === "true"
          ? new Date().toString()
          : payload.archiveDate,
    };

    const data = await axios.post(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/curated-profiles`,
      newPayload,
    );

    return dispatch({
      type: CREATE_CURATED_PROFILE,
      payload: data.data,
    });
  } catch (error) {
    return error;
  }
};

export const updateCuratedProfile = (payload) => async (dispatch) => {
  try {
    const newPayload = {
      ...payload,
      routine:
        !payload.archiveDate && payload.isArchived === "true" ? true : false,
      archiveDate:
        !payload.archiveDate && payload.isArchived === "true"
          ? new Date().toString()
          : payload.archiveDate,
    };
    const data = await axios.patch(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/curated-profiles/${payload.id}`,
      newPayload,
    );
    return dispatch({
      type: UPDATE_CURATED_PROFILE,
      payload: data.data,
    });
  } catch (error) {
    console.log("error", error);
    return error;
  }
};

export const deleteCuratedProfile = (payload) => async (dispatch) => {
  try {
    const data = await axios.delete(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/curated-profiles/${payload.id}`,
    );
    return dispatch({
      type: DELETE_CURATED_PROFILE,
      payload: data.data,
    });
  } catch (error) {
    return error;
  }
};

export const createCoin = (payload) => async (dispatch) => {
  try {
    const data = await axios.post(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/curated-profiles/${payload.curatedProfileId}/coins`,
      payload,
    );
    return dispatch({
      type: CREATE_COIN,
      payload: data.data,
    });
  } catch (error) {
    return error;
  }
};

export const updateCoin = (payload) => async (dispatch) => {
  try {
    const data = await axios.patch(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/coins/${payload.id}`,
      payload,
    );
    return dispatch({
      type: UPDATE_COIN,
      payload: data.data,
    });
  } catch (error) {
    console.log("error", error);
    return error;
  }
};

export const deleteCoin = (payload) => async (dispatch) => {
  try {
    const data = await axios.delete(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/coins/${payload.id}`,
    );
    return dispatch({
      type: DELETE_COIN,
      payload: data.data,
    });
  } catch (error) {
    return error;
  }
};
