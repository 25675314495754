import {
  FETCH_ALL_USERS,
  FAILED_ALL_USER_FETCH,
  UPDATE_USER_PERMISSION,
  UPDATE_USER_CLEARANCELEVEL,
  FAILED_USER_UPDATE_PERMISSION,
  INVITE_USER,
  FETCH_ALL_ORDERS,
  FETCH_ALL_LEADS,
  FAILED_USER_INVITE,
  FAILED_TO_FETCH_ALL_ORDERS,
  FETCH_AUTHENTICATION_CODES,
} from "../actions/types";

const initialState = {
  users: [],
  usersError: "",
  updatePermMessage: "",
  updatePermError: "",
  inviteUserMessage: "",
  updateClearanceMessage: "",
  inviteUserError: "",
  orders: [],
  leads: [],
  salesReport: [],
  authenticationCodes: [],
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case "FETCH_SALES_REPORT":
      return {
        ...state,
        salesReport: action.payload,
      }
    case FETCH_ALL_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case FAILED_ALL_USER_FETCH:
      return {
        ...state,
        usersError: action.payload,
      };
    case FETCH_ALL_ORDERS:
      return {
        ...state,
        orders: action.payload,
      };
    case FETCH_ALL_LEADS:
      return {
        ...state,
        leads: action.payload,
      };
    case FAILED_TO_FETCH_ALL_ORDERS:
      return {
        ...state,
        orderError: action.payload,
      };
    case UPDATE_USER_PERMISSION:
      return {
        ...state,
        updatePermMessage: action.payload,
      };
      case UPDATE_USER_CLEARANCELEVEL:
      return {
        ...state,
        updateClearanceMessage: action.payload,
      };
    case FAILED_USER_UPDATE_PERMISSION:
      return {
        ...state,
        updatePermError: action.payload,
      };
    case INVITE_USER:
      return {
        ...state,
        inviteUserMessage: action.payload,
      };
    case FAILED_USER_INVITE:
      return {
        ...state,
        inviteUserError: action.payload,
      };
    case FETCH_AUTHENTICATION_CODES:
      return {
        ...state,
        authenticationCodes: action.payload,
      };
    default:
      return state;
  }
}
