import React, { useState } from "react";
import { Alert, Input, Switch } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { updateUserDiscordServer } from "../../store/actions/permissions";
import { message } from "antd";
import { connect } from "react-redux";

const DiscordServerForm = (props) => {
  const {
    handleInputChange,
    username,
    email,
    discordId,
    formError,
    updateUserDiscordServer,
  } = props;

  const [isDiscordServer, setIsDiscordServer] = useState(!!discordId);

  const onChange = async (checked) => {
    if (checked && !username) {
      message.error("Username é obrigatório para atualizar o Discord Server.");
      return;
    }

    setIsDiscordServer(checked);

    try {
      if (checked) {
        updateUserDiscordServer({ action: "join", username });
        message.success("Sucesso em adicionar o usuário!");
      } else {
        updateUserDiscordServer({ action: "cancel", email });
        message.success("Sucesso em remover o usuário!");
      }
    } catch (err) {
      message.error("Erro ao atualizar o Discord Server.");
    }
  };

  return (
    <div>
      {formError ? (
        <div style={{ marginBottom: 25 }}>
          <Alert type="error" message={formError} />
        </div>
      ) : null}

      <div
        style={{
          marginTop: 25,
          display: "flex",
          alignItems: "center",
          gap: 10,
        }}
      >
        <Switch
          checked={isDiscordServer}
          defaultValue={isDiscordServer}
          defaultChecked={isDiscordServer}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={onChange}
        />
        <label>{isDiscordServer ? "Remove Discord" : "Join Discord"}</label>
      </div>

      {isDiscordServer ? (
        <div style={{ marginTop: 25 }}>
          <label>Email</label>
          <Input
            value={email}
            defaultValue={email}
            onChange={(e) => handleInputChange(e, "email")}
          />
        </div>
      ) : (
        <div style={{ marginTop: 25 }}>
          <label>Username</label>
          <Input
            value={username}
            defaultValue={username}
            onChange={(e) => handleInputChange(e, "username")}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  updatePermMessage: state.permissions.updatePermMessage,
  updatePermError: state.permissions.updatePermError,
});

export default connect(mapStateToProps, {
  updateUserDiscordServer,
})(DiscordServerForm);
