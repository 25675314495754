import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Alert, Table, Tag } from "antd";
import { fetchAuthenticationCodes } from "../../store/actions/permissions";

const AuthenticationCodes = (props) => {
  const { userId, fetchAuthenticationCodes, authenticationCodes, formError } =
    props;

  useEffect(() => {
    fetchAuthenticationCodes(userId);
  }, [fetchAuthenticationCodes, userId]);

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "CreatedAt",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Status",
      dataIndex: "deletedAt",
      key: "deletedAt",
      render: (_, record) => {
        let color;
        let status;

        if (record.deletedAt) {
          status = "Expirado";
          color = "red";
        } else if (record.used) {
          status = "Usado";
          color = "blue";
        } else {
          const now = new Date();
          const expiresAt = new Date(record.expiresAt);

          if (expiresAt < now) {
            status = "Expirado";
            color = "red";
          } else {
            status = "Ativo";
            color = "green";
          }
        }

        return <Tag color={color}>{status}</Tag>;
      },
    },
  ];

  return (
    <div>
      {formError ? (
        <div style={{ marginBottom: 25 }}>
          <Alert type="error" message={formError} />
        </div>
      ) : null}

      <div style={{ marginTop: 25 }}>
        <Table columns={columns} dataSource={authenticationCodes} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authenticationCodes: state.permissions.authenticationCodes,
});

const mapDispatchToProps = {
  fetchAuthenticationCodes,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthenticationCodes);
