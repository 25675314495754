import React from "react";
import { Alert, Input, Checkbox } from "antd";

const DetailsForm = (props) => {
  const {
    userData,
    formError,
  } = props;
  const excludeFields = [
    "pplan", 
    "isVideoCreator", 
    "isAuthor", 
    "clearanceLevel",
    "subscriptionInfo",
    "paymentInfo",
    "discord_id",
  ];

  const renderInput = (key, value) => {
    if (typeof value === "boolean") {
      return (
        <>
          <Checkbox
            id={key}
            name={key}
            label={key}
            checked={value}
            onChange={() => {}}
            disabled
          />
          <label htmlFor={key} style={{ marginLeft: 10 }}>{key}</label>
      </>
      );
    }
    return (
      <>
        <label htmlFor={key}>{key}</label>
        <Input
          id={key}
          name={key}
          value={value || ""}
          onChange={() => {}}
          disabled
        />
      </>
    );
  };

  return (
    <div>
      {formError ? (
        <div style={{ marginBottom: 25 }}>
          <Alert type="error" message={formError} />
        </div>
      ) : null}

      {Object.keys(userData).map((key) => {
        if (excludeFields.includes(key)) {
          return null;
        }

        return (
          <div key={key} style={{ marginTop: 25 }}>
              {renderInput(key, userData[key])}
          </div>
        );
      })}
    </div>
  );
};

export default DetailsForm;
